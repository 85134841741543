// @flow
import * as React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Header from './Header';
import Footer from './Footer';
import Container from './Container';
import PushNotifications from './PushNotifications/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): React.Node {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <PushNotifications />
      <QueryClientProvider client={queryClient}>
        <Container />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
