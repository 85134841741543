// @flow
import type { AdvertiserSimple } from 'advertiser';

export const FETCH_ADVERTISERS_BEGIN = 'FETCH_ADVERTISERS_BEGIN';
export const FETCH_ADVERTISERS_SUCCESS = 'FETCH_ADVERTISERS_SUCCESS';
export const FETCH_ADVERTISERS_FAILURE = 'FETCH_ADVERTISERS_FAILURE';

type FetchAdvertisersBegin = {
  type: typeof FETCH_ADVERTISERS_BEGIN,
};

type FetchAdvertisersSuccess = {
  type: typeof FETCH_ADVERTISERS_SUCCESS,
  payload: { advertisers: AdvertiserSimple[] },
};

type FetchAdvertisersFailure = {
  type: typeof FETCH_ADVERTISERS_FAILURE,
  payload: { error: Object },
};

export type Action = FetchAdvertisersBegin | FetchAdvertisersSuccess | FetchAdvertisersFailure;

export function fetchAdvertisersBegin(): FetchAdvertisersBegin {
  return {
    type: FETCH_ADVERTISERS_BEGIN,
  };
}

export function fetchAdvertisersSuccess(advertisers: AdvertiserSimple[]): FetchAdvertisersSuccess {
  return {
    type: FETCH_ADVERTISERS_SUCCESS,
    payload: { advertisers },
  };
}

export function fetchAdvertisersFailure(error: Object): FetchAdvertisersFailure {
  return {
    type: FETCH_ADVERTISERS_FAILURE,
    payload: { error },
  };
}

export function fetchAdvertisers() {
  return async (dispatch: (Action) => void) => {
    const API = (await import('services/api')).default;

    dispatch(fetchAdvertisersBegin());

    API.get('party/simple')
      .then(({ data }) => dispatch(fetchAdvertisersSuccess(data)))
      .catch((err) => dispatch(fetchAdvertisersFailure(err)));
  };
}
