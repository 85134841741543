// @flow
import * as React from 'react';

import { connect } from 'react-redux';
import { deletePushNotification } from 'state/actions/pushNotifications';

import Banner from './Banner';

import type { PushNotification } from 'push-notification';
import type { State as PushNotificationState } from 'state/reducers/pushNotifications';

type OwnProps = {||};

type Props = {
  ...OwnProps,
  notifications: PushNotification[],
  deleteNotification: (number) => void,
};

type State = {
  pushNotifications: PushNotificationState,
};

function PushNotifications({ notifications, deleteNotification }: Props): React.Node {
  return notifications.map<React.Node>(({ id, title, message }, index) => (
    <Banner key={id} title={title} message={message} onDelete={() => deleteNotification(index)} />
  ));
}

const mapStateToProps = ({ pushNotifications }: State) => ({
  notifications: pushNotifications,
});

const mapDispatchToProps = (dispatch: (Function) => void) => ({
  deleteNotification: (index: number) => dispatch(deletePushNotification(index)),
});

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(PushNotifications);
